import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useGetAllProjectQuery } from '../home/homeApiSlice';
import { Spinner } from '@chakra-ui/react';
import { GoChevronLeft } from 'react-icons/go';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const Project = () => {
  const {
    data: projects,
    error,
    isLoading,
  } = useGetAllProjectQuery({ type: 'project' });

  const textEffect = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: { duration: 1.2, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(projects?.projects.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(
    startIndex + itemsPerPage,
    projects?.projects.length
  );
  const currentAnnouncements = projects?.projects;
  const goToPage = page => {
    setCurrentPage(Math.max(1, Math.min(page, totalPages)));
    scrollToTop();
  };

  return (
    <>
      <div className="bg-[#0F1320] min-h-[100px] min-w-screen"></div>
      <div className="bg-[#F0F0F0]">
        <div className="ml-4 lg:ml-8 mt-8 flex flex-row">
          <GoChevronLeft className="text-yellow-500 mt-1" />
          <a href="/">
            <button className="text-yellow-500 font-bold text-md">
              Back to Home
            </button>
          </a>
        </div>
        <motion.div
          variants={textEffect}
          initial={'offscreen'}
          whileInView={'onscreen'}
          viewport={{ once: true }}
          className="max-w-7xl mx-auto w-screen min-h-screen"
        >
          <h1 className="flex justify-center mt-4 py-6 text-5xl text-black font-extrabold">
            Projects
          </h1>
          <div className="flex flex-col xl:flex-row xl:space-x-4 mb-4 xl:mb-8">
            <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
            <p className="flex justify-center text-md font-light py-4 text-center lg:px-4">
              Browse through our meaningful social projects aimed at making a
              difference in the lives of individuals and communities.
            </p>
            <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
          </div>

          <div className="relative">
            {isLoading ? (
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <Spinner
                  size={'xl'}
                  thickness="4px"
                  emptyColor="gray.200"
                  color="blue.500"
                />
              </div>
            ) : projects?.projects.length === 0 ? (
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  fontSize: '50px',
                  paddingTop: '10px',
                }}
              >
                No Projects
              </div>
            ) : (
              currentAnnouncements.map((project, index) => {
                const formattedDate = project?.date?.split('T')[0];
                const date = new Date(formattedDate).toLocaleString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                });
                return (
                  <>
                    <div className="absolute top-0 left-1/2 w-1 bg-[#FDBC06] h-full z-0"></div>
                    <motion.div
                      variants={textEffect}
                      initial={'offscreen'}
                      whileInView={'onscreen'}
                      viewport={{ once: true }}
                      key={index}
                      className={`flex flex-col xl:flex-row ${
                        index % 2 === 0 ? 'xl:justify-end' : 'xl:justify-start'
                      } items-center py-8 ${
                        index > 0 ? 'xl:-mt-[20%]' : ''
                      } relative z-10`}
                    >
                      <div
                        className={`w-full flex justify-center xl:${
                          index % 2 === 0 ? 'justify-end' : 'justify-start'
                        } px-4 xl:px-0`}
                      >
                        <Link
                          to={`/project/projectDetail/${index + startIndex}`}
                          state={projects}
                          className="cursor-pointer w-full max-w-md rounded-lg shadow-lg overflow-hidden z-10"
                        >
                          <img
                            src={
                              project?.images[0]?.url ||
                              'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg'
                            }
                            alt={`Project ${index}`}
                            className="w-full h-96 object-contain"
                          />
                          <div className="bg-white p-4 space-y-4">
                            <p className="text-xl font-bold">
                              {project.projectName}
                            </p>
                            <p className="text-md text-[#8A8A8A]">{date}</p>
                            <p className="text-md text-[#8A8A8A]"></p>
                          </div>
                        </Link>
                      </div>
                      <div className="hidden xl:block w-6 h-6 bg-white border-4 border-[#FDBC06] rounded-full absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10"></div>
                    </motion.div>
                  </>
                );
              })
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Project;
